import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "list" ]

    connect() {
        console.log("Connected to StimulusJS Thread Controller!")
        this.scrollToBottom()
        document.addEventListener('turbo:morph', () => this.scrollToBottom())
    }

    disconnect() {
        document.removeEventListener('turbo:morph', () => this.scrollToBottom())
    }

    scrollToBottom() {
        requestAnimationFrame(() => {
            window.scrollTo(0, document.body.scrollHeight);
        })
    }
}