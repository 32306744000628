import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-tabs"
export default class extends Controller {
  static targets = ["button", "menu"]

  connect() {
    // Close menu when clicking outside
    this.clickHandler = this.handleClickOutside.bind(this)
    document.addEventListener("click", this.clickHandler)
  }

  disconnect() {
    document.removeEventListener("click", this.clickHandler)
  }

  toggle(event) {
    event.stopPropagation()
    this.menuTarget.classList.toggle("hidden")

    // Toggle aria-expanded
    const isExpanded = !this.menuTarget.classList.contains("hidden")
    this.buttonTarget.setAttribute("aria-expanded", isExpanded)

    // Rotate chevron icon
    const icon = this.buttonTarget.querySelector("svg")
    icon.style.transform = isExpanded ? "rotate(180deg)" : ""
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains("hidden")) {
      this.menuTarget.classList.add("hidden")
      this.buttonTarget.setAttribute("aria-expanded", false)
      this.buttonTarget.querySelector("svg").style.transform = ""
    }
  }
}
