import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slideover-without-overlay"
export default class extends Controller {
  static targets = ['slideover'];

  connect() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.checkScreenSize.bind(this));
  }

  toggleSlideover() {
    if (this.slideoverTarget.classList.contains('hidden')) {
      this.showSlideover();
    } else {
      this.hideSlideover();
    }
  }

  showSlideover() {
    this.slideoverTarget.classList.remove('hidden');
  }

  hideSlideover() {
    this.slideoverTarget.classList.add('hidden');
  }

  checkScreenSize() {
    const isSmScreen = window.innerWidth < 640;

    if (isSmScreen && !this.slideoverTarget.classList.contains('hidden')) {
      this.hideSlideover();
    }
  }
}