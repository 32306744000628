import Reveal from '@stimulus-components/reveal'

// Connects to data-controller="reveal"
export default class extends Reveal  {
  connect(){
    super.connect()
    console.log("connected")
  }

  toggle() {
    console.log("toggle method called")
    super.toggle()
    // this.element.querySelectorAll('.icon-wrapper').forEach((item) => {
    //   console.log(item)
    //   item.classList.toggle('hidden')
    // })
  }
}
