import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-patient-form"
export default class extends Controller {
    static targets = ['select', 'formField', 'formFieldWrapper']

    connect() {
        this.selectTarget.addEventListener('change', () => this.toggleForm())
        this.toggleForm()
    }

    toggleForm() {
        if (this.selectTarget.value === "-1") {
            this.formFieldTargets.forEach(field => {
                field.disabled = false
                if (!field.name.includes('middle_name')) {
                    field.setAttribute('required', '')
                }
                // field.value = ""
            })
            this.formFieldWrapperTargets.forEach(wrapper => {
                wrapper.hidden = false
            })
        } else {
            this.formFieldTargets.forEach(field => {
                field.disabled = true
                if (!field.name.includes('middle_name')) {
                    field.removeAttribute('required')
                }
                // field.value = ""
            })
            this.formFieldWrapperTargets.forEach(wrapper => {
                wrapper.hidden = true
            })
        }
    }
}
