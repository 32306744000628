// app/javascript/controllers/threaded_measure_updates_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleIcon", "toggleText", "repliesContent", "parentIdField"]
  static values = {
    parentId: String
  }

  connect() {
  }

  toggleReplies(event) {
    // Get the toggle button
    const button = event.currentTarget
    // Get the icon and text elements
    const icon = this.toggleIconTarget
    // Get the replies content
    const repliesContent = this.repliesContentTarget

    // Toggle visibility
    if (repliesContent.classList.contains('hidden')) {
      // Show replies
      repliesContent.classList.remove('hidden')
      icon.classList.add('rotate-90')
      this.toggleTextTarget.textContent = `Hide ${this.replyCountText}`
    } else {
      // Hide replies
      repliesContent.classList.add('hidden')
      icon.classList.remove('rotate-90')
      this.toggleTextTarget.textContent = `Show ${this.replyCountText}`
    }
  }

  // Helper to get reply count text from the current state
  get replyCountText() {
    const replyCount = this.repliesContentTarget.querySelectorAll('[data-test="reply-item"]').length
    return `${replyCount} ${replyCount === 1 ? 'reply' : 'replies'}`
  }

  setParentId(event) {
    const parentId = event.currentTarget.dataset.threadedMeasureUpdatesParentIdValue

    if (this.hasParentIdFieldTarget) {
      this.parentIdFieldTarget.value = parentId
    }
  }
}
