import { Controller } from "@hotwired/stimulus"
import { marked } from 'marked';
import hljs from 'highlight.js';

export default class extends Controller {
    static targets = ["content"]

    connect() {
        this.renderTargets()
        document.addEventListener('turbo:morph', () => this.renderTargets())
    }

    disconnect() {
        document.removeEventListener('turbo:morph', () => this.renderTargets())
    }

    renderTargets() {
        this.contentTargets.forEach(target => {
            target.innerHTML = this.markdownToHtml(target.textContent, false)
        })
    }

    markdownToHtml(text) {
        const renderer = new marked.Renderer()

        renderer.code = (code, language) => {
            const validLanguage = hljs.getLanguage(language) ? language : 'plaintext'
            const highlighted = hljs.highlight(code, {language: validLanguage}).value
            const encodedCode = encodeURIComponent(code);
            return `<pre><div class="flex p-0 justify-between bg-[#00346e] border-bottom-1 border-[#7285b7]"><span class="pl-4 pt-2">${validLanguage}</span><button class="btn btn-light content-end focus:outline-none focus:shadow-none focus:ring-0 focus:ring-offset-0 focus:text-gray-500 hover:text-gray-500" data-controller="clipboard" data-clipboard-text="${encodedCode}"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pr-2"><path stroke-linecap="round" stroke-linejoin="round" d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" /></svg><span class="font-light text-sm">Copy</span></button></div><code class="hljs ${validLanguage}">${highlighted}</code></pre>`
        }

        let html = marked(text, { renderer })

        return html
    }
}