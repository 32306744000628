import { Controller } from "@hotwired/stimulus"
import FormController from "./form_controller"

// Connects to data-controller="aida-month-filter"
export default class extends FormController {
  static targets = ["filter", "month", "year"]

  connect() {
    super.connect()
    this.updateHiddenFields()
  }

  updateHiddenFields() {
    this.filterTarget.addEventListener('change', (event) => {
      const [month, year] = event.target.value.split('/')
      this.monthTarget.value = month
      this.yearTarget.value = year
    })
  }

  submit() {
    this.filterTarget.disabled = true;
    super.submit();
    this.filterTarget.disabled = false;
  }
}
