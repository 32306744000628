import { BridgeComponent } from "@hotwired/strada"
import { BridgeElement } from "@hotwired/strada"

// Connects to data-controller="bridge--navigation-drawer"
export default class extends BridgeComponent {
    static component = "navigation-drawer"
    static targets = [ "item", "btn" ]

    connect() {
        super.connect()
        this.notifyBridgeOfConnect()
    }

    notifyBridgeOfConnect() {
        const items = this.makeMenuItems(this.itemTargets)

        this.send("connect", { items }, message =>  {
            const selectedIndex = message.data.selectedIndex
            const selectedItem = new BridgeElement(this.itemTargets[selectedIndex])

            selectedItem.click()
        })
    }

    makeMenuItems(elements) {
        const items = elements.map((element, index) => this.menuItem(element, index))
        const enabledItems = items.filter(item => item)

        return enabledItems
    }

    menuItem(element, index) {
        const bridgeElement = new BridgeElement(element)

        if (bridgeElement.disabled) return null

        let data = {
            title: bridgeElement.title,
            index: index
        };

        let icon = bridgeElement.bridgeAttribute("icon");
        let fontFamily = bridgeElement.bridgeAttribute("font-family");
        let fontPackage = bridgeElement.bridgeAttribute("font-package");
        let divider = bridgeElement.bridgeAttribute("divider");
        let label = bridgeElement.bridgeAttribute("label")

        if(icon) {
            data["icon"] = icon
        }

        if(fontFamily) {
            data["fontFamily"] = fontFamily
        }

        if(fontPackage) {
            data["fontPackage"] = fontPackage
        }

        if(divider) {
            data["divider"] = divider
        }

        if(label) {
            data["label"] = label
        }

        return data
    }
}