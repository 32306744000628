import { Controller } from "@hotwired/stimulus"
import debounce from 'debounce';

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["filter", "rangeFilter"]

  initialize() {
    this.submit = debounce(this.submit.bind(this), 300);
  }

  submit() {
    this.element.requestSubmit();
  }

  clear() {
    this.filterTargets.forEach(filter => filter.value = "")
    this.submit();
  }

  clearRange() {
    this.rangeFilterTargets.forEach(filter => filter.value = "")
    this.submit();
  }
}
