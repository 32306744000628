import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat-form"
export default class extends Controller {
  static targets = ["form", "chatInput", "submitButton"]

  connect() {
    this.chatInputTarget.addEventListener("input", this.disableSubmit.bind(this))
    this.disableSubmit({ target: this.chatInputTarget })
  }

  submit(event) {
    event.preventDefault()
    this.formTarget.requestSubmit()
    this.clearInputAfterSubmit()
  }


  clearInputAfterSubmit() {
    if (this.chatInputTarget.value.trim() !== "") {
      this.chatInputTarget.value = ""
    }
  }

  disableSubmit(event) {
    const isDisabled = this.chatInputTarget.value.trim() === ""
    this.submitButtonTarget.disabled = isDisabled
    this.submitButtonTarget.classList.toggle("opacity-50", isDisabled)
    this.submitButtonTarget.classList.toggle("cursor-not-allowed", isDisabled)
    this.submitButtonTarget.classList.toggle("cursor-pointer", !isDisabled)
  }
}
