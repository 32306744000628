import { Controller } from "@hotwired/stimulus"
import debounce from 'debounce';

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ["entries", "pagination", "loader"]
  isLoading = false
  smallScreenWidth = 640

  connect() {
    this.scroll = debounce(this.scroll.bind(this), 200)
    window.addEventListener('scroll', this.scroll)
  }

  disconnect() {
    this.scroll.cancel()
    window.removeEventListener('scroll', this.scroll)
  }

  scroll() {
    // Check if the screen size is small enough for infinite scroll
    if (window.innerWidth >= this.smallScreenWidth) {
      return
    }

    // Check if the next page is already being loaded
    if (this.isLoading) {
      return
    }

    let nextPage = this.paginationTarget.querySelector("a[rel='next']")

    if (nextPage == null || nextPage.classList.contains('disabled')) {
      this.hideLoader();
      window.removeEventListener('scroll', this.scroll);
    } else {
      let url = nextPage.href

      let body = document.body,
          html = document.documentElement

      let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

      if (window.scrollY >= height - window.innerHeight - 100) {
        this.showLoader()
        this.isLoading = true
        this.loadMore(url)
      }
    }
  }

  showLoader() {
    this.loaderTarget.classList.remove('hidden');
  }

  hideLoader() {
    this.loaderTarget.classList.add('hidden');
  }

  loadMore(url) {
    this.showLoader()
    this.isLoading = true

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;
        this.isLoading = false;
        this.hideLoader();
      }
    })
  }
}