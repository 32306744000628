// app/javascript/controllers/exemptions_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "checkbox", "reason", "reasonLabel"]

  connect() {
    if (this.hasCheckboxTarget) {
      this.toggleReasonInput()
    }
  }

  toggleReasonInput() {
    const isChecked = this.checkboxTarget.checked;
    if (this.hasFieldTarget) {
      this.fieldTarget.required = !isChecked;
      this.fieldTarget.disabled = isChecked;
    }
    if (this.hasReasonTarget) {
      this.reasonTarget.classList.toggle("hidden", !isChecked);
    }
    if (this.hasReasonLabelTarget) {
      this.reasonLabelTarget.classList.toggle("hidden", !isChecked);
    }
  }

  toggle(event) {
    this.toggleReasonInput();
  }
}
