import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="range"
export default class extends Controller {
  static targets = [ "range", "number" ]

  connect() {
    this.rangeTarget.value = this.numberTarget.value;
  }

  update() {
    this.numberTarget.value = this.rangeTarget.value;
  }

  updateRange() {
    this.rangeTarget.value = this.numberTarget.value;
  }
}
