import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chart-download"
export default class extends Controller {
  static values = {
    filename: { type: String, default: 'chart' }
  }

  download() {
    const canvas = this.element.querySelector('canvas')
    if (canvas) {
      const link = document.createElement('a')
      link.download = `${this.filenameValue}.png`
      link.href = canvas.toDataURL('image/png')
      link.click()
    }
  }
}
