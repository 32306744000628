import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-modal"
export default class extends Controller {
  static targets = ["modal"]

  connect() {
    // This method is called when the controller is connected to the DOM
  }

  open(event) {
    event.preventDefault()
    this.modalTarget.classList.remove('hidden')
    document.body.classList.add('overflow-hidden')
  }

  close(event) {
    event.preventDefault()
    this.modalTarget.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }

  closeBackground(event) {
    if (event.target === this.modalTarget) {
      this.close(event)
    }
  }
}
