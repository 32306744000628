import {Tabs} from "tailwindcss-stimulus-components"

// Connects to data-controller="tabs"
export default class extends Tabs {
  connect() {
    this.showTab()
    super.connect()
  }

  change(event) {
    this.indexValue = this.tabTargets.indexOf(event.currentTarget)

    super.change(event)
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      if (index === this.indexValue) {
        this.inactiveTabClasses.forEach(cls => tab.classList.remove(cls))
        this.activeTabClasses.forEach(cls => tab.classList.add(cls))
      } else {
        this.activeTabClasses.forEach(cls => tab.classList.remove(cls))
        this.inactiveTabClasses.forEach(cls => tab.classList.add(cls))
      }
    })

    super.showTab()
  }

  get activeTabClasses() {
    return (this.data.get('activeTabClass') || '').split(' ').filter(Boolean)
  }

  get inactiveTabClasses() {
    return (this.data.get('inactiveTabClass') || '').split(' ').filter(Boolean)
  }
}
