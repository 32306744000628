import { BridgeComponent } from "@hotwired/strada"
import { BridgeElement } from "@hotwired/strada"

// Connects to data-controller="bridge--menu"
export default class extends BridgeComponent {
  static component = "menu"
  static targets = [ "title", "item" ]

  show(event) {
    if (this.enabled) {
      event.stopImmediatePropagation()
      this.notifyBridgeToDisplayMenu(event)
    }
  }

  notifyBridgeToDisplayMenu(event) {
    const title = new BridgeElement(this.titleTarget).title
    const items = this.makeMenuItems(this.itemTargets)

    this.send("display", { title, items }, message =>  {
      const selectedIndex = message.data.selectedIndex
      const selectedItem = new BridgeElement(this.itemTargets[selectedIndex])

      selectedItem.click()
    })
  }

  makeMenuItems(elements) {
    const items = elements.map((element, index) => this.menuItem(element, index))
    const enabledItems = items.filter(item => item)

    return enabledItems
  }

  menuItem(element, index) {
    const bridgeElement = new BridgeElement(element)
    
    if (bridgeElement.disabled) return null

    let data = {
      title: bridgeElement.title,
      index: index
    };

    let bridgeElementColor = bridgeElement.bridgeAttribute("color")

    if(bridgeElementColor) {
      data["color"] = bridgeElementColor
    }

    return data
  }
}