import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "input" ]

    connect() {
        this.resize()
    }

    resize() {
        this.inputTarget.style.height = 'auto'
        var scrollHeight = this.inputTarget.scrollHeight
        if(scrollHeight<=40)
            this.inputTarget.style.height = `40px`
        else
            this.inputTarget.style.height = `${scrollHeight}px`
    }

    grow() {
        this.resize()
    }
}