import { Controller } from "@hotwired/stimulus"

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
}

// Connects to data-controller="geolocation"
export default class extends Controller {
    connect() {
        if (window.location.pathname.includes('new') && !this.hasLocationParams()) {
            this.search()
        }
    }

    hasLocationParams() {
        return new URLSearchParams(window.location.search).has('location')
    }

    success(pos) {
        const crd = pos.coords;

        console.log('Your current location is:');
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude : ${crd.longitude}`);

        if (window.location.pathname.includes('new')) {
            location.assign(`/encounters/sessions/new?location=${crd.latitude},${crd.longitude}`);
        }
    }

    error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    search() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.success.bind(this), this.error.bind(this), options);
        }
    }
}
