import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox"
export default class extends Controller {
  static targets = [ 'checkBox', 'controlledSelect', 'controlledInput' ];

  connect() {
  }

  update() {
    if (this.checkBoxTarget.checked){
      this.controlledSelectTarget.disabled = false
      this.controlledInputTarget.disabled = false
    } else {
      this.controlledSelectTarget.disabled = true
      this.controlledSelectTarget.hidden = true
      this.controlledInputTarget.disabled = true
      this.controlledSelectTarget.value = "";
      this.controlledInputTarget.value = "";
    }
  }
}
