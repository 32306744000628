// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

import { Dropdown, Modal, Tabs, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
// application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import NestedForm from 'stimulus-rails-nested-form'
application.register('nested-form', NestedForm)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import Reveal from '@stimulus-components/reveal'
application.register('reveal', Reveal)

import Popover from '@stimulus-components/popover'
application.register('popover', Popover)

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})