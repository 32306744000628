import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-more"
export default class extends Controller {
  static targets = [ "entry", "moreButton", "lessButton" ]

  connect() {
    this.hiddenClass = this.data.get('hiddenClass') || 'hidden'
    this.initialShowCount = this.data.get('showCount') || 5
    this.showCount = this.initialShowCount
    this.update()

    this.handleTurboRender = this.reset.bind(this)
    document.addEventListener('turbo:render', this.handleTurboRender)
  }

  disconnect() {
    document.removeEventListener('turbo:render', this.handleTurboRender)
  }

  reset() {
    this.showCount = this.initialShowCount
    this.update()
  }

  update() {
    this.entryTargets.forEach((el, index) => {
      el.classList.toggle(this.hiddenClass, index >= this.showCount)
    })

    this.moreButtonTarget.classList.toggle(this.hiddenClass, this.entryTargets.length <= this.showCount)
    this.lessButtonTarget.classList.toggle(this.hiddenClass, this.showCount <= this.initialShowCount)
  }

  showMore() {
    this.showCount = this.entryTargets.length
    this.update()
  }

  showLess() {
    this.showCount = this.initialShowCount
    this.update()
  }
}