import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "updateType", "updateTypeField",
    "yearField", "statusField", "revisedDescriptionField", "commentField",
    "challengesField", "actionPlanField", "actionPlanImplementedField",
    "scorecardCommentField"
  ]

  connect() {
    this.initialize()
    document.addEventListener("turbo:render", () => this.initialize())
  }

  disconnect() {
    document.removeEventListener("turbo:render", () => this.initialize())
  }

  initialize() {
    if (this.hasUpdateTypeTarget) {
      this.updateFormFields()
    }
  }

  updateFormFields() {
    const updateType = this.updateTypeTarget.value
    const fieldsToShow = this.getFieldsForUpdateType(updateType)

    // Hide and clear fields that shouldn't be visible
    this.constructor.targets.forEach(targetName => {
      if (targetName !== 'updateType') {
        if (this.hasTarget(targetName) && !fieldsToShow.includes(targetName)) {
          this.hideAndClearField(targetName)
        }
      }
    })

    // Show fields that should be visible
    fieldsToShow.forEach(fieldName => {
      if (this.hasTarget(fieldName)) {
        this.showField(fieldName)
      }
    })
  }

  getFieldsForUpdateType(updateType) {
    switch(updateType) {
      case 'revision':
        return ['revisedDescriptionField', 'commentField']
      case 'comment':
        return ['commentField']
      case 'end_of_year':
      case 'mid_year':
        return [
          'updateTypeField',
          'yearField',
          'statusField',
          'commentField',
          'challengesField',
          'actionPlanField',
          'actionPlanImplementedField',
          'scorecardCommentField'
        ]
      default:
        return []
    }
  }

  hasTarget(targetName) {
    return this[`has${targetName.charAt(0).toUpperCase()}${targetName.slice(1)}Target`]
  }

  hideAndClearField(targetName) {
    const target = this[`${targetName}Target`]
    if (target) {
      // Hide the field
      target.classList.add('hidden')

      // Clear the field value and remove `required` attribute
      const inputs = target.querySelectorAll('input, textarea, select')
      inputs.forEach(input => {
        switch(input.type) {
          case 'checkbox':
            input.checked = false
            break
          case 'number':
          default:
            input.value = ''
        }
        input.removeAttribute('required')  // Remove required attribute
      })
    }
  }

  showField(fieldName) {
    const target = this[`${fieldName}Target`]
    if (target) {
      target.classList.remove('hidden')

      // Add the required attribute if the field is essential
      const inputs = target.querySelectorAll('input, textarea, select')
      inputs.forEach(input => {
        if (this.isFieldRequired(fieldName)) {
          input.setAttribute('required', '')
        }
      })
    }
  }

  isFieldRequired(fieldName) {
    // Define required fields based on update type
    const requiredFieldsByUpdateType = {
      revision: ['revisedDescriptionField'],
      comment: ['commentField'],
      end_of_year: ['yearField', 'statusField', 'commentField'],
      mid_year: ['yearField', 'statusField', 'commentField']
    }

    const updateType = this.updateTypeTarget.value
    return requiredFieldsByUpdateType[updateType]?.includes(fieldName) || false
  }
}
