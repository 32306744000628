import {Slideover} from "tailwindcss-stimulus-components"

// Connects to data-controller="slideover"
export default class extends Slideover {
  static targets = ['content']

  setContent(event) {
    const contentId = event.target.dataset.contentId;
    const content = document.getElementById(contentId);
    this.contentTarget.innerHTML = content.innerHTML;
  }

  resetForm() {
    const form = document.getElementById('approval-group-form')
    form.reset()
  }

  toggle(event) {
    super.toggle(event);
    this.resetForm()
  }
}
