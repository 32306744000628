import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js"

// Connects to data-controller="autocalculate-currency"
export default class extends Controller {
  static targets = ['operand', 'total']

  connect() {
    this.cleaveInstances = []
    this.totalTarget.value = this.totalTarget.value / 100
    const cleaveInstance = new Cleave(this.totalTarget, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalScale: 2,
      numeralDecimalMark: '.',
      delimiter: ',',
      prefix: '$',
      rawValueTrimPrefix: true
    })
    this.cleaveInstances.push(cleaveInstance)

    this.operandTargets.forEach(element => {
      element.value = element.value / 100
      const cleaveInstance = new Cleave(element, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        numeralDecimalMark: '.',
        delimiter: ',',
        prefix: '$',
        rawValueTrimPrefix: true
      })
      this.cleaveInstances.push(cleaveInstance)
    })
  }

  disconnect() {
    this.cleaveInstances.forEach((cleaveInstance) => {
      cleaveInstance.destroy()
    })
  }

  updateTotal() {
    const totalValue = this.calculate()
    this.totalTarget.value = totalValue
    this.cleaveInstances[0].setRawValue(totalValue)
  }

  calculate() {
    let sum = 0
    this.operandTargets.forEach((element, index) => {
      if (element.value.trim() !== '' && element.disabled === false) {
        let rawValue = this.cleaveInstances[index + 1].getRawValue()
        let parsedValue = parseFloat(rawValue.replace(/[$,]/g, ''))
        sum += parsedValue || 0
      }
    })
    return sum
  }

  submit(event) {
    event.preventDefault()
    this.totalTarget.value = parseFloat(this.cleaveInstances[0].getRawValue().replace(/[$,]/g, '')) * 100
    this.operandTargets.forEach((element, index) => {
      if (element.value.trim() !== '' && element.disabled === false) {
        element.value = parseFloat(this.cleaveInstances[index + 1].getRawValue().replace(/[$,]/g, '')) * 100
      }
    })
    event.target.submit()
  }
}
