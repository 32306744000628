import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js";

// Connects to data-controller="autocalculate-effort"
export default class extends Controller {
  static targets = ['academicTotalFTE', 'academicTeachingFTE', 'academicTitleFTE',
    'administrativeTotalFTE', 'administrativeGeneralFTE', 'administrativeTitleSubtotalFTE', 'administrativeTitleFTE',
    'clinicalTotalFTE', 'clinicalBenchmark', 'clinicalExpectation',
    'researchTotalFTE', 'researchFundedFTE', 'researchNonFundedFTE', 'researchSalary', 'researchSalarySupport',
    'totalFTE', 'totalSalaryInCents']

  connect() {
    this.researchSalaryTarget.value = this.researchSalaryTarget.value / 100
    this.researchSalarySupportTarget.value = this.researchSalarySupportTarget.value / 100

    this.cleaveInstances = new Map()

    this.cleaveInstances.set(
        "clinical_benchmark",
        new Cleave(this.clinicalBenchmarkTarget, {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 0,
          delimiter: ','
        })
    )
    this.cleaveInstances.set(
        "clinical_expectation",
        new Cleave(this.clinicalExpectationTarget, {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 0,
          delimiter: ','
        })
    )
    this.cleaveInstances.set(
        "research_salary",
        new Cleave(this.researchSalaryTarget, {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2,
          numeralDecimalMark: '.',
          delimiter: ',',
          prefix: '$',
          rawValueTrimPrefix: true
        })
    )
    this.cleaveInstances.set(
        "research_salary_support",
        new Cleave(this.researchSalarySupportTarget, {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2,
          numeralDecimalMark: '.',
          delimiter: ',',
          prefix: '$',
          rawValueTrimPrefix: true
        })
    )
  }

  update() {
    this.updateAcademic()
    this.updateAdministrative()
    this.updateClinical()
    this.updateResearch()
    this.updateTotal()
  }

  updateAcademic() {
    let teaching = parseFloat(this.academicTeachingFTETarget.value)
    let title_subtotal = 0
    this.academicTitleFTETargets.forEach((element, index) => {
      title_subtotal += parseFloat(element.value) || 0
    })

    let sum = 0 + teaching + title_subtotal

    this.academicTotalFTETarget.value = sum.toFixed(2)
  }

  updateAdministrative() {
    let general = parseFloat(this.administrativeGeneralFTETarget.value)
    let title_subtotal = 0
    this.administrativeTitleFTETargets.forEach((element, index) => {
      title_subtotal += parseFloat(element.value) || 0
    })

    let sum = 0 + general + title_subtotal

    this.administrativeTitleSubtotalFTETarget.value = title_subtotal.toFixed(2)
    this.administrativeTotalFTETarget.value = sum.toFixed(2)
  }

  updateClinical() {
    if (this.clinicalBenchmarkTarget.value.trim() !== '') {
      let fte = parseFloat(this.clinicalTotalFTETarget.value)
      let benchmark = parseInt(this.cleaveInstances.get("clinical_benchmark").getRawValue().replace(/,/g, ''))
      let expectation = fte * benchmark
      this.clinicalExpectationTarget.value = Math.round(expectation)
      this.cleaveInstances.get("clinical_expectation").setRawValue(expectation)
    }
  }

  updateResearch() {
    let funded = parseFloat(this.researchFundedFTETarget.value)
    let non_funded = parseFloat(this.researchNonFundedFTETarget.value)
    let sum = 0 + funded + non_funded

    if (this.totalSalaryInCentsTarget.value != null) {
      let total_salary = parseInt(this.totalSalaryInCentsTarget.value) / 100
      let research_salary = sum * total_salary
      let research_salary_support = research_salary * 0.6

      this.researchSalaryTarget.value = research_salary
      this.cleaveInstances.get("research_salary").setRawValue(research_salary)

      this.researchSalarySupportTarget.value = research_salary_support
      this.cleaveInstances.get("research_salary_support").setRawValue(research_salary_support)
    }

    this.researchTotalFTETarget.value = sum.toFixed(2)
  }

  updateTotal() {
    let academic = parseFloat(this.academicTotalFTETarget.value)
    let administrative = parseFloat(this.administrativeTotalFTETarget.value)
    let clinical = parseFloat(this.clinicalTotalFTETarget.value)
    let research = parseFloat(this.researchTotalFTETarget.value)
    let sum = 0 + academic + administrative + clinical + research

    this.totalFTETarget.value = sum.toFixed(2)
  }

  submit(event) {
    event.preventDefault()
    this.clinicalBenchmarkTarget.value = parseInt(this.cleaveInstances.get("clinical_benchmark").getRawValue().replace(/,/g, ''))
    this.clinicalExpectationTarget.value = parseInt(this.cleaveInstances.get("clinical_expectation").getRawValue().replace(/,/g, ''))
    this.researchSalaryTarget.value = parseFloat(this.cleaveInstances.get("research_salary").getRawValue().replace(/[$,]/g, '')) * 100
    this.researchSalarySupportTarget.value = parseFloat(this.cleaveInstances.get("research_salary_support").getRawValue().replace(/[$,]/g, '')) * 100

    event.target.submit()
  }
}
