import { Controller } from "@hotwired/stimulus"
import debounce from 'debounce';

// Connects to data-controller="token-counter"
export default class extends Controller {
  static targets = [ "promptInput", "messageInput", "tokenCount" ]

  countTokens = debounce((event) => {
      const initialTokenCount = parseInt(this.tokenCountTarget.dataset.tokenCounterInitialValue)

      const promptInputTarget = this.tryPromptInputTarget();
      let promptContent = null
      const messageContent = this.messageInputTarget.value

      const model = this.data.get("modelValue")
      const searchId = this.data.get("conversationIdValue")
      let url = `/aida/conversations/${searchId}/count_tokens`
      let params = {}

      if (promptInputTarget) {
          promptContent = promptInputTarget.value
          params = { prompt: promptContent, content: messageContent, model: model }
      } else {
          params = { content: messageContent, model: model }
      }


      if (promptContent == null && messageContent === '') {
          this.tokenCountTarget.textContent = initialTokenCount
      } else {
          fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
              },
              body: JSON.stringify(params)
          })
              .then(response => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  return response.json();
              })
              .then(data => {
                  let totalTokenCount = data.token_count
                  this.tokenCountTarget.textContent = totalTokenCount
              })
              .catch(error => {
                  console.log('There was a problem with the fetch operation: ' + error.message);
              });
      }
  }, 300);

  tryPromptInputTarget() {
      try {
          return this.promptInputTarget;
      } catch (error) {
          return null;
      }
  }
}
