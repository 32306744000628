import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file"
export default class extends Controller {
    connect() {
        this.toggleClearButtons();
    }

    clear(event) {
        event.preventDefault();
        const fileField = document.getElementById(event.currentTarget.dataset.fileTarget);

        const firstFileField = document.getElementById('face_sheets-file-field');
        const secondFileField = document.getElementById('face_sheets_second-file-field');

        if (fileField.value) {
            fileField.value = '';
            let previewId = fileField.id + '-preview';
            let preview = document.getElementById(previewId);
            if (preview) {
                preview.innerHTML = '';
            }
            this.toggleClearButton(fileField);

            if (fileField.id === 'face_sheets-file-field') {
                if (secondFileField && secondFileField.value) {
                    secondFileField.value = '';
                    let previewId = secondFileField.id + '-preview';
                    let preview = document.getElementById(previewId);
                    if (preview) {
                        preview.innerHTML = '';
                    }
                    this.disableSecondFileField();
                }
            }
        }

        if (firstFileField && secondFileField && !firstFileField.value && !secondFileField.value) {
            this.disableSecondFileField();
        }
    }


    previewImage(event) {
        let input = event.target;
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = function(e) {
                let previewId = input.id + '-preview';
                let preview = document.getElementById(previewId);
                preview.innerHTML = `<img src="${e.target.result}" class="w-64 h-64 object-contain border border-1"/>`;
                if (input.id === 'face_sheets-file-field') {
                    this.enableSecondFileField();
                }
                this.toggleClearButton(input);
            }.bind(this);
            reader.readAsDataURL(input.files[0]);
        }
    }

    enableSecondFileField() {
        const secondFileField = document.getElementById('face_sheets_second-file-field');
        const secondFileFieldLabel = document.querySelector('label[for="face_sheets_second-file-field"]');

        secondFileField.disabled = false;
        secondFileField.classList.remove('hidden');
        secondFileFieldLabel.classList.remove('hidden');
    }

    disableSecondFileField() {
        const secondFileField = document.getElementById('face_sheets_second-file-field');
        const secondFileFieldLabel = document.querySelector('label[for="face_sheets_second-file-field"]');

        secondFileField.disabled = true;
        secondFileField.classList.add('hidden');
        secondFileFieldLabel.classList.add('hidden');
    }


    toggleClearButtons() {
        console.log("toggle buttons");
        const fileFields = this.element.querySelectorAll('input[type="file"]');
        let anyFieldHasValue = false;

        fileFields.forEach((fileField) => {
            if (fileField.value) {
                anyFieldHasValue = true;
            }
            this.toggleClearButton(fileField);
        });
    }

    toggleClearButton(fileField) {
        const targetId = fileField.id.split("-")[0]
        const clearButtonId = `${targetId}-clear-button`;
        const clearButton = document.getElementById(clearButtonId);
        if (fileField && clearButton) {
            if (fileField.value) {
                clearButton.style.display = 'inline-flex';
            } else {
                clearButton.style.display = 'none';
            }
        }
    }
}