import { BridgeComponent } from "@hotwired/strada"
import {BridgeElement} from "@hotwired/strada";

// Connects to data-controller="bridge--fab"
export default class extends BridgeComponent {
  static component = "fab"
  static targets = [ "link" ]

  connect() {
    super.connect()
    this.notifyBridgeOfConnect()
  }

  notifyBridgeOfConnect() {
    const linkButton = new BridgeElement(this.linkTarget)
    const fabTitle = linkButton.title
    const fabIcon = linkButton.bridgeAttribute("icon")

    this.send("connect", { fabTitle, fabIcon }, () => {
      this.linkTarget.click()
    })
  }
}