import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="time"
export default class extends Controller {
  static values = { status: String, startTime: Number, endTime: Number }

  connect() {
    this.updateInterval = setInterval(() => {
      this.updateRuntime()
    }, 1000)
  }

  disconnect() {
    clearInterval(this.updateInterval)
  }

  updateRuntime() {
    const currentTime = Math.floor(Date.now() / 1000)
    let runtime = currentTime - this.startTimeValue

    if (this.statusValue === "completed" || this.statusValue === "failed") {
      runtime = this.endTimeValue - this.startTimeValue
    }

    this.element.textContent = this.runtimeInWords(runtime)
  }

  runtimeInWords(secondsDiff) {
    if (this.statusValue == 'queued' || secondsDiff == null) {
      return "-"
    }

    const hours = Math.floor(secondsDiff / 3600)
    const minutes = Math.floor((secondsDiff / 60) % 60)
    const seconds = secondsDiff % 60

    let runtime = ""
    if (hours > 0) {
      runtime += `${hours}h `
    }
    if (minutes > 0) {
      runtime += `${minutes}m `
    }
    runtime += `${seconds}s`

    return runtime
  }
}
