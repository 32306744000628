import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="icon-select"
export default class extends Controller {
  static targets = [ "dropdown", "button", "icon", "iconDisplay", "toggleButton" ]

  connect() {
    this.toggle()
    this.updateButton()
    this.highlightSelectedIcon()
  }

  select(event) {
    this.buttonTarget.value = event.currentTarget.dataset.value
    this.updateButton()
    this.highlightSelectedIcon()
  }

  toggle() {
    this.dropdownTarget.classList.toggle('hidden')
  }

  updateButton() {
    const value = this.buttonTarget.value
    if (value) {
      const newIcon = document.createElement('i')
      newIcon.className = `fa-solid fa-${value}`
      this.iconDisplayTarget.innerHTML = ''
      this.iconDisplayTarget.appendChild(newIcon)
    } else {
      this.iconDisplayTarget.innerHTML = '-'
    }
    this.toggle()
  }

  highlightSelectedIcon() {
    this.iconTargets.forEach(icon => {
      if (icon.dataset.value === this.buttonTarget.value) {
        icon.classList.remove('bg-white', 'hover:bg-gray-100');
        icon.classList.add('bg-gray-200', 'hover:bg-gray-50');
      } else {
        icon.classList.remove('bg-gray-200', 'hover:bg-gray-50');
        icon.classList.add('bg-white', 'hover:bg-gray-100');
      }
    })
  }
}
