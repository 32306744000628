/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

import "@hotwired/strada"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

// Flowbite
import "flowbite/dist/flowbite.turbo.js";

import "chartkick/chart.js"

// Start Rails UJS
Rails.start()

// Document ready function for Rails with TurboLinks or Hotwire
// document.addEventListener('turbo:load', () => {
//     // Apply highlight.js to all elements with the 'highlight' class
//     document.querySelectorAll('pre code').forEach((block) => {
//         hljs.highlightBlock(block);
//     });
// });
