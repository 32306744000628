import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-upload"
export default class extends Controller {
  static targets = ["input", "preview", "fileList"]

  browse(event) {
    event.preventDefault()
    this.inputTarget.click()
  }

  handleFiles(event) {
    const files = Array.from(event.target.files)
    this.displayFiles(files)
  }

  dragover(event) {
    event.preventDefault()
    event.currentTarget.classList.add('bg-blue-50')
  }

  dragleave(event) {
    event.preventDefault()
    event.currentTarget.classList.remove('bg-blue-50')
  }

  drop(event) {
    event.preventDefault()
    event.currentTarget.classList.remove('bg-blue-50')

    // Check file types
    const files = Array.from(event.dataTransfer.files).filter(file => {
      const validTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png'
      ]
      return validTypes.includes(file.type)
    })

    // Update the actual file input
    const dt = new DataTransfer()
    files.forEach(file => dt.items.add(file))
    this.inputTarget.files = dt.files

    this.displayFiles(files)
  }

  displayFiles(files) {
    this.fileListTarget.innerHTML = ''

    files.forEach(file => {
      const fileSize = this.formatFileSize(file.size)
      const fileElement = document.createElement('div')
      fileElement.className = 'flex items-center py-2'

      // Choose icon based on file type
      let icon
      if (file.type.startsWith('image/')) {
        icon = `<svg class="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>`
      } else if (file.type.includes('pdf')) {
        icon = `<svg class="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
        </svg>`
      } else {
        icon = `<svg class="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>`
      }

      fileElement.innerHTML = `
        <div class="mr-2">${icon}</div>
        <div class="flex-1 min-w-0">
          <p class="text-sm font-medium text-gray-900 truncate">${file.name}</p>
          <p class="text-sm text-gray-500">${fileSize}</p>
        </div>
        <button type="button" 
                class="ml-4 text-sm font-medium text-red-600 hover:text-red-500"
                data-action="click->file-upload#removeFile">
          Remove
        </button>
      `

      this.fileListTarget.appendChild(fileElement)
    })
  }

  removeFile(event) {
    const fileElement = event.currentTarget.closest('div.flex')
    const fileIndex = Array.from(this.fileListTarget.children).indexOf(fileElement)

    const dt = new DataTransfer()
    const files = Array.from(this.inputTarget.files)

    files.forEach((file, index) => {
      if (index !== fileIndex) {
        dt.items.add(file)
      }
    })

    this.inputTarget.files = dt.files
    fileElement.remove()
  }

  formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }
}
