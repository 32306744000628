import { Controller } from "@hotwired/stimulus"
import { marked } from 'marked';
import hljs from 'highlight.js';

// Connects to data-controller="highlight"
export default class extends Controller {
  static targets = ["editor", "preview", "tab"]
  static values = { type: String }

  connect() {
    this.updatePreview();
  }

  showEditor(event) {
    this.toggleEditor(true);
    this.toggleTab(event.currentTarget, true);
  }

  showPreview(event) {
    this.toggleEditor(false);
    this.toggleTab(event.currentTarget, false);
  }

  toggleEditor(showEditor) {
    if (this.hasEditorTarget) {
      this.editorTarget.classList.toggle('hidden', !showEditor);
      this.editorTarget.classList.toggle('block', showEditor);
      this.previewTarget.classList.toggle('hidden', showEditor);
      this.previewTarget.classList.toggle('block', !showEditor);
    }
  }

  toggleTab(tab, isEditor) {
    this.tabTargets.forEach(tab => {
      tab.classList.remove('selected', 'bg-gray-100', 'text-gray-900', 'hover:bg-gray-200')
      tab.classList.add('bg-white', 'text-gray-500', 'hover:bg-gray-100', 'hover:text-gray-900')
    })
    tab.classList.remove('bg-white', 'text-gray-500', 'hover:bg-gray-100', 'hover:text-gray-900')
    tab.classList.add('selected', 'bg-gray-100', 'text-gray-900', 'hover:bg-gray-200')
  }

  updatePreview() {
    if (this.typeValue === 'markdown') {
      const markdown = this.hasEditorTarget ? this.editorTarget.value : this.data.get('content');
      this.previewTarget.innerHTML = marked(markdown)
    } else if (this.typeValue === 'json') {
      const json = this.hasEditorTarget ? this.editorTarget.value : this.data.get('content');
      try {
        const parsed = JSON.parse(json)
        const highlighted = hljs.highlight(JSON.stringify(parsed, null, 2), {language: 'json'}).value
        this.previewTarget.innerHTML = `<pre><code>${highlighted}</code></pre>`
      } catch (e) {
        // Invalid JSON, handle the error
        this.previewTarget.innerHTML = 'Invalid JSON'
      }
    }
  }
}